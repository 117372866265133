@import '../../global.scss';

.resource-balance {
    box-sizing: border-box;
    padding: 1rem 1rem 0.5rem;
    max-width: 750px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    
    @include media('<=1000px') {
        gap: 0.5rem;
    }
    
   
}