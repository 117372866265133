.modal-button-bar {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
        box-sizing: border-box;
        padding: 0.5rem;
    }
    button {
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2px;
        transition: border 0.25s, background-color 0.25s;
        box-shadow: 0 0 14px -2px #000;
    }
    .cancel-button {
        background-color: transparent;
        color : #CCC;
        border: 1px solid #CCC;
        opacity: 0.7;
        &:hover {
           color: #fff; 
           border: 1px solid #FFF;
        }
    }
    .approve-button {
        background-color: #4e4631;
        border: 1px solid #746849;
        color: #FFF;
        
        &:hover {
            border: 1px solid #94845c;
            background-color: #685d42;
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}