@import './global.scss';

$borderWidth : 4px;
$spacePadding: 16px;

$borderWidthMedium : 4px;
$spacePaddingMedium: 12px;


html {
  -webkit-tap-highlight-color:transparent;
  outline: none;
  height: 100%;
}
body {
  background-color: #000;
  font-family: 'Cormorant', serif;
  margin: 0;
  border: 0;
  color: #fff;
  overflow-x: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

.the-gold-crow {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  //overflow: hidden;

  &.auth {
 // grid-template-columns: 1fr 568px;
  
  @include media('<=desktop-large') {
   // grid-template-columns: 1fr 428px;
  }
  @include media('<=1150px') {
    //grid-template-columns: 1fr;
    //grid-template-rows: 1fr 224px;
  }
  @include media('<=tablet') {
    //grid-template-columns: 1fr;
    //grid-template-rows: 1fr 128px;
  }
}  
  .page-pane {
   // min-height: 100%;
    height: auto;
    //overflow-x: hidden;
    position: relative;
    @include media('<=1150px') {
      height: auto;
    }
  }


  .inventory-pane {
    
    overflow: hidden;
    &.vertical {
      height: 100vh;
      display: block;
      @include media('<1150px') {
        display: none;
      }
    }
    &.horizontal {
      display: none;
      @include media('<=1150px') {
        height: 224px;
        display: block;
      }
      @include media('<=tablet') {
        height: 128px;
        display: block;
      }
    }
  }
}

.drag-item {
  position: fixed;
  z-index: 100000;
  //background-color: #fff;
  pointer-events: none;
  //opacity: 0;
  width: $figureLarge;
    height: $figureLarge;
    
    transition: opacity 0.25s;
  @include media('<=desktop-large') {
    width: $figureMedium;
    height: $figureMedium;
}
@include media('<=tablet') {
  width: $figureSmall;
  height: $figureSmall;
}
  .drag-wrapper {
    pointer-events: none;
   
  }
  &.show {
    opacity: 1;
    .drag-wrapper {
      
    }
  }
  
 
}


.show-inventory {
  .page-pane {
    margin-right: 568px;
    @include media('<=desktop-large') {
      margin-right: 428px;
     }
     @include media('<=1150px') {
      margin-right: 0;
      padding-bottom: 224px;
      .inventory {
        display: none;
      }
    }
    @include media('<=tablet') {
      margin-right: 0;
      padding-bottom: 128px;
    }
  }
  
}


.fade-in {
 

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
}

.fade-in-fast {
 

  -webkit-animation: fadein 0.25s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 0.25s; /* Firefox < 16 */
      -ms-animation: fadein 0.25s; /* Internet Explorer */
       -o-animation: fadein 0.25s; /* Opera < 12.1 */
          animation: fadein 0.25s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.scroll-wrapper {
  overflow-y: auto;
  position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    box-sizing: border-box;
}


.viewer {
  img {
    max-width: 768px !important;
    opacity: 0;
    transition: opacity 0.25s ease-in;
    &.show {
      opacity: 1;
    }
  }
}

.experiment-nav {
  position:absolute;
  left: 0;
  top: 0;
  z-index: 10001;
}

.loading-indicator {
  pointer-events: none;
  font-size: 1.2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  z-index: 30000;
  height: 0;
  transition: height 0.3s ease-in;
  &.show {
    height: 50px;
  }
}

$loader-size: 20px;
$loader-speed: 2s;
$loader-widht: 2px;
$loader-color: #FFFFFF;


.pulse-loader {
    height: $loader-size;
    margin: auto 0;
    position: relative;
    width: $loader-size;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-top: -4px;
    &:before,
    &:after {
      animation: pulse-loader infinite $loader-speed;
      box-shadow: 0 0 5px $loader-color;
      border-radius: 50%;
      border: $loader-widht solid $loader-color;
      content: '';
      display: block;
      height: 100%;
      left: -#{$loader-widht};
      opacity: 0;
      position: absolute;
      top: -#{$loader-widht};
      width: 100%;
    }
  
    &:after {
      animation-delay: $loader-speed / 2;
    }
  }
  
  @keyframes pulse-loader {
    0% {
      opacity: 0;
      transform: scale(0)
    }
    60% {
      opacity: 1
    }
    100% {
      opacity: 0;
      transform: scale(1)
    }
  }

  
.border {
  pointer-events: none;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 22px 3px #000;
  opacity: 0.7;
  .border-top {
      
      background-image: url('/image/border-top.png');
      //background-size: 100%;
      background-size: auto $borderWidth;
      background-repeat: repeat-x;
      left: 0; 
      right: 0;
      top: 0;
      height: $borderWidth;
      display: block;
      position: absolute;
      z-index: 200;
      &:before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          width: $borderWidth;
          height: $borderWidth;
          overflow: hidden;
          background-image: url('/image/border-corner.png');
          background-size: $borderWidth * 2;
          background-position: left top;
          z-index: 300;
      }
      &:after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          width: $borderWidth;
          height: $borderWidth;
          overflow: hidden;
          background-image: url('/image/border-corner.png');
          background-size: $borderWidth * 2;
          background-position: right top;
          z-index: 300;
      }
  }

  .border-bottom {
      background-image: url('/image/border-bottom.png');
      background-size: auto $borderWidth;
      background-repeat: repeat-x;
      left: 0; 
      right: 0;
      bottom: 0;
      height: $borderWidth;
      display: block;
      position: absolute;
      z-index: 200;
      &:before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          width: $borderWidth;
          height: $borderWidth;
          overflow: hidden;
          background-image: url('/image/border-corner.png');
          background-size: $borderWidth * 2;
          background-position: left bottom;
          z-index: 300;
      }
      &:after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          width: $borderWidth;
          height: $borderWidth;
          overflow: hidden;
          background-image: url('/image/border-corner.png');
          background-size: $borderWidth * 2;
          background-position: right bottom;
          z-index: 300;
      }
  }


  .border-sides {
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     position: absolute;
     z-index: 100;
      &:before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          bottom: 0;
          width: $borderWidth;
          background-repeat: repeat-y;
          overflow: hidden;
          background-image: url('/image/border-left.png');
          background-size: $borderWidth auto;
      }
      &:after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          bottom: 0;
          width: $borderWidth;
          background-repeat: repeat-y;
          overflow: hidden;
          background-image: url('/image/border-right.png');
          background-size: $borderWidth auto;
      }
    }
  }