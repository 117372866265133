@import '../../global.scss';


$circleLarge : 65;
$circleMedium : 55;
$circleSmall : 55;
$circleTiny : 55;

.expanding-circle {
    width: 100%;
    height: 100%;
    // position:absolute;
    // left: 50%;
    // top: 46%;
    // transform: translate3d(-50%, -50%, 0);

    // overflow: visible;
    // width: $circleLarge * 1vw;
    // height: $circleLarge * 1vh;
    // max-width: $circleLarge * 1vh;
    // max-height: $circleLarge * 1vw;
    // @include media('<=desktop-large') {
    //     width: $circleMedium * 1vw;
    //     height: $circleMedium * 1vh;
    //     max-width: $circleMedium * 1vh;
    //     max-height: $circleMedium * 1vw;
    // }
    // @include media('<=desktop') {
    //     width: $circleSmall * 1vw;
    //     height: $circleSmall * 1vh;
    //     max-width: $circleSmall * 1vh;
    //     max-height: $circleSmall * 1vw;
    // }
    // @include media('<=tablet') {
    //     width: $circleTiny * 1vw;
    //     height: $circleTiny * 1vh;
    //     max-width: $circleTiny * 1vh;
    //     max-height: $circleTiny * 1vw;
    // }
    canvas {
        //opacity: 0.07;
    }

    // .cicle-channels {
    //     position: relative;
    //     width: 65vw;
    //     height: 65vh;
    //     max-width: 65vh;
    //     max-height: 65vw;  
    // }
    
    
}