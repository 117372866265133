@import '../../../../global.scss';

.completed-set {
    display: grid;
    position: relative;
    .special {
        position: absolute;
    left: -17px;
    top: -17px;
    opacity: 0.5;
        svg {
            path {
                fill: #a19679;
            }
        }
    }
    .completed-set-display {
        z-index: 100;
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr;
        @include media('<=1000px') {
            gap: 0.25rem;
            //grid-template-columns: 1fr 1fr;
        }
    }
    .completed-set-users {
        padding: 0.5rem 0;
        span {
            color: #bababa;
            &.is-user {
                color: #a19679;
            }
        }
    }
}