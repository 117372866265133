@import '../../global.scss';



.page-nav {
    
        position: relative;
        margin-bottom: 1rem;
        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            background-image: url('../../image/border-bottom.png');
            display: block;
            width: 100%;
            opacity: 0.5;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 0;
            li {
                display: inline-block;
                font-size: 1rem;
                a {
                    display: block;
                    padding: 1rem 1rem;
                    font-family: 'Nanum Gothic Coding', monospace;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #888;
                    transition: color 0.25s ease-in;
                    font-size: 1.2rem;
                    &.active {
                        color: #a19679;
                    }
                    &:hover {
                        color: #fff;
                    }
                    @include media('<=tablet') {
                        font-size: 1rem;
                        padding: 0.75rem;
                     }
                }
                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }
            }
        }
    
}