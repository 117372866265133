@import '../../global.scss';


.home-page {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  min-height: 1200px;
  //margin-bottom: 120px;
  .epoch-counter {

  }
  .menu-bar {
    ul {
      
      list-style-type: none;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      li {
        display: inline-block;
        a {
          font-size: 1rem;
          padding: 0 1rem;
          color: #777;
          text-decoration: none;
          text-transform: uppercase;
          line-height: 60px;
          font-weight: 500;
          @include media('<=tablet') {
            line-height: 45px;
            font-size: 0.85rem;
            padding: 0 0.35rem;
          }
        }
      }
    }
  }
  .epoch-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 2rem;
    .header {
      padding-top: 10rem;
      @include media('<=tablet') {
        padding-top: 7rem;
      }
      img {
        transform: translateX(-13px);
        margin: 0 auto;
        display: block;
        width: 50%;
    max-width: 256px;
    @include media('<=tablet') {
      max-width: 128px;
    }
      }
    }
  }
  h1 {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    .chapter-title {
      @include media('<=tablet') {
        display: block;
      }
      @include media('<=phone') {
        display: block;
        font-size: 1.7rem;
      }
      
    }
    
    .sub-title {
      a {
        text-decoration: none;
        color: #fff;
      }
      @include media('<=tablet') {
        display: block;
        font-size: 1.4rem;
      }
      @include media('<=phone') {
        display: block;
        font-size: 1.2rem;
      }
    }
  }
  h2 {
    text-align: center;
    color: #fff;
  }

  .sockets {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    margin: 2rem 0;
    grid-template-columns: $figureLarge $figureLarge $figureLarge;
    align-items: center;
  justify-content: center;
                @include media('<=desktop-large') {
                  grid-template-columns: $figureMedium $figureMedium $figureMedium;
                }
                @include media('<=phone') {
                  grid-template-columns: $figureSmall $figureSmall $figureSmall;
                   
                }



    @include media('<=phone') {
      column-gap: 1rem;
    }
    .socket {
      background-color: #363636;
    height: 0;
    padding-top: 100%;
    border: 1px solid #6c6c6c;
    box-shadow: inset 5px 5px 14px -5px #000;
    opacity: 0.6;
    @include media('<=tablet') {
      background-size: contain;
    }
    &.key1 {
      
      background-image: url('/key1.png');
      background-repeat: no-repeat;
      background-position: center center;
      
    }
    &.key2 {
      
      background-image: url('/key2.png');
      background-repeat: no-repeat;
      background-position: center center;
      
    }
    &.key3 {
      
      background-image: url('/key3.png');
      background-repeat: no-repeat;
      background-position: center center;
      
    }
    }
  }
  .clock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    height: 60px;
    .panel {
      small {
        display: block;
        font-size: 1rem;
      }
      font-size: 2rem;
      text-align: center;
      color: #fff;
    }
  }
}

.animation {
  position: absolute;
  left: 0;
  right: 0;
  height: 500px;
  top: 64px;
  z-index: -1;
  .ring {
    will-change: transform;
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 100%;
    border: 2px solid #ccc;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
  }
  .ball {
    will-change: transform;
    -webkit-animation: rotating 10s linear infinite;
    -moz-animation: rotating 10s linear infinite;
    -ms-animation: rotating 10s linear infinite;
    -o-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
    
    position: absolute;
    
    top: 50%;
    transform: translateX(-50%);
    width: 500px;
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      left: -9px;
      border-radius: 100%;
      background-color: #fff;
      display: block;
      position: absolute;
    }
  }

  .ring2 {
    will-change: transform;
    position: absolute;
    width: 700px;
    height: 700px;
    border-radius: 100%;
    border: 2px solid #ccc;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    .ball {
      -webkit-animation: rotating 15s linear infinite;
    -moz-animation: rotating 15s linear infinite;
    -ms-animation: rotating 15s linear infinite;
    -o-animation: rotating 15s linear infinite;
    animation: rotating 15s linear infinite;
    
      width: 700px;
    }
  }
  
  .ring3 {
    will-change: transform;
    position: absolute;
    width: 900px;
    height: 900px;
    border-radius: 100%;
    border: 2px solid #ccc;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    .ball {
      -webkit-animation: rotating 20s linear infinite;
    -moz-animation: rotating 20s linear infinite;
    -ms-animation: rotating 20s linear infinite;
    -o-animation: rotating 20s linear infinite;
    animation: rotating 20s linear infinite;
   
    
      width: 900px;
      &:after {
        background-color: transparent;
        border: 2px solid #fff;
        left: -25px;
        width:50px;
        height: 50px;
      }
    }
  }

  .ring4 {
    will-change: transform;
    position: absolute;
    width: 1000px;
    height: 1000px;
    border-radius: 100%;
    border: 2px solid #ccc;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    .ball {
      -webkit-animation: rotating 20s linear infinite;
    -moz-animation: rotating 20s linear infinite;
    -ms-animation: rotating 20s linear infinite;
    -o-animation: rotating 20s linear infinite;
    animation: rotating 20s linear infinite;
   
    
      width: 1000px;
      &:after {
        background-color: transparent;
        border: 2px solid #fff;
        left: -25px;
        width:50px;
        height: 50px;
      }
    }
  }

  .ring5 {
    will-change: transform;
    position: absolute;
    width: 1100px;
    height: 1100px;
    border-radius: 100%;
    border: 2px solid #ccc;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    .ball {
      -webkit-animation: rotating 15s linear infinite;
    -moz-animation: rotating 15s linear infinite;
    -ms-animation: rotating 15s linear infinite;
    -o-animation: rotating 15s linear infinite;
    animation: rotating 15s linear infinite;
   
    
      width: 1100px;
      &:after {
        background-color: transparent;
        border: 2px solid #fff;
        left: -49px;
        width:100px;
        height: 100px;
      }
    }
  }
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}


.figure-plate {
  margin-top: 3.1rem;
  box-sizing: border-box;
  padding: 2rem;
  background-color: #000;
  
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  box-shadow: 0px -1px 20px 20px #000;
  @include media('<=phone') {
    padding: 0.75rem;
  }
  .figure-content {
    max-width: 920px;
    margin: 0 auto;
  }
}

.contract {
  color: #fff;
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
position: fixed;
left: 0;
right: 0;
bottom: 0;
line-height: 100px;
    font-size: 1.8rem;
    text-align: center;
    z-index: 10001;
    @include media('<=tablet') {
      font-size: 1.4rem;
    }
    @include media('<=phone') {
      font-size: 1rem;
      line-height: 50px;
    }
}


.contract {
  color: #fff;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 100px;
    font-size: 1.8rem;
    text-align: center;
    z-index: 10001;
    @include media('<=tablet') {
      font-size: 1.4rem;
    }
    @include media('<=phone') {
      font-size: 1rem;
      line-height: 50px;
    }
}

.show-inventory {
  .contract {
    right: 568px;
    @include media('<=desktop-large') {
      right: 428px;
      
     }
    @include media('<=1150px') {
      right: 0;
      bottom: 224px;
      
    }
    @include media('<=tablet') {
      right: 0;
      bottom: 128px;
    }
  }
}


.the-keepers {
  padding-top:4rem;
  text-align: center;
  span {
    opacity: 0;
    display: block;
  }
  svg {
    margin: 0 auto;
    display: inline-block;
    
  }
  
}

.epoch-wrapper {
  height: 60px;
}



.fade-in {
 

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.viewer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
  background-color: #000000b0;
  padding: 1rem;
  z-index: 10001;
  .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    text-transform: uppercase;
    color: #ccc;
    text-decoration: none;
    
  }
  img {
    margin: 0 auto;
    display: block;
    
    max-width: 1297px;
    width: 90%;
    
  }
}




::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


// .auth {
//   .contract {
//     bottom: -1px;
//     right: 568px;
//     @include media('<=desktop-large') {
//      right: 428px;
//     }
//     @include media('<=1150px') {
//       bottom: 224px;
//       right: 0;
//     }
//     @include media('<=tablet') {
//       bottom: 128px;
//       right: 0;
//     }
//     @include media('<=phone') {
//       bottom:  128px;
//       right: 0;
//     }
//   }
// }