@import '../../global.scss';

.experiment-nav {
    width: 100%;
    ul {
        list-style-type: none;
        font-size: 0;
        display: block;
        margin: 0;
        padding:0;
        height: 30px;
        border-bottom: 1px solid rgb(41, 41, 41);
        li {
            text-transform: uppercase;
            font-size: 1.2rem;
            text-align: center;
            display: inline-block;
            padding: 0 0.3rem;
            font-family: 'Nanum Gothic Coding', monospace;
            line-height: 30px;
            @include media('<=desktop-large') {
                font-size: 1rem;
            }
            @include media('<=1000px') {
                font-size: 0.9rem;
            }
            a {
                text-decoration: none;
                color: #666;
                &.active {
                    color:#a19679;
                }
            }
        }
    }
}