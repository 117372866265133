@import '../../../global.scss';

.mintable-item {
    .figure-display {
        box-shadow: -5px 5px 30px -1px #000;
    }
    &.expired {
        .figure-display {
            
        }
    }
    .mintable-item-details {
        padding: 1rem 1rem;
        box-sizing: border-box;
        //border: 1px solid #45423a;
        background-color: #00000063;
        border-radius: 2px;
        margin: 1rem 0;
        &:last-child {
            margin-bottom: 0;
        }
        dl {
            margin: 0;
            padding: 0;
            .data-item {
                display: grid;
                grid-template-columns: 1fr 2fr;
                font-size: 0.9rem;
                font-family: 'Nanum Gothic Coding', monospace;
                margin: 0.5rem 0;
                color: #bababa;
                @include media('<=1000px') {
                    grid-template-columns: 1fr;
                    margin: 1rem 0;
                    font-size: 0.75rem;
                 }
                 &:first-child {
                     margin-top: 0;
                 }
                 &:last-child {
                     margin-bottom: 0;
                 }
            }
            dt {
                margin-bottom: 0.25rem;
            }
            dd {
                text-align: right;
                margin: 0;
                a {
                    margin-top: 0.5rem;
                    display: inline-block;
                    color: #a19679;
                    text-transform: uppercase;
                    text-decoration: none;
                }
                @include media('<=1000px') {
                   text-align: left;
                    
                 }
            }
        }
    }
    .info-text {
        font-family: 'Nanum Gothic Coding', monospace;
        text-align: right;
        margin-bottom: 0.5rem;
        margin-top: -0.5rem;
    color: #bababa;
    font-size: 1rem;
    @include media('<=1000px') {
        font-size: 0.9rem;
        text-align: center;
      }
        div {
            display: inline-block;
            text-transform: uppercase;
        }
        
    }
    .mint-area {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .total {
            text-align: left;
            font-family: 'Nanum Gothic Coding', monospace;
            font-size: 1.2rem;
            color: #c1b083;
            @include media('<=1000px') {
                font-size: 0.9rem;
                 
              }
            &.no-cost {
                opacity: 0.5;
            }
            img {
                width: 32px;
                margin-right: 0.5rem;
                vertical-align: middle;
                opacity: 0.6;
                @include media('<=1000px') {
                    width: 28px;
                     
                  }
            }
            line-height: 32px;
        }
    }
    .mint-button, .pending-mint-button, .expired-mint-button {
        background-color: #4e4631;
        border: 1px solid #746849;
        color: #FFF;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2px;
        transition: border 0.25s, background-color 0.25s;
        box-shadow: 0 0 14px -2px #000;
        &:hover {
            border: 1px solid #94845c;
            background-color: #685d42;
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .pending-mint-button {
        opacity: 0.5;
    pointer-events: none;
        display: inline-block;
        div {
            display: inline-block;
        }
    }
    .expired-mint-button {
        opacity: 0.5;
    pointer-events: none;
        display: inline-block;
        background-color: #222;
        border: 1px solid #333;
        div {
            display: inline-block;
        }
    }
}

.minting-modal {
    width: 320px;
    h2 {
        margin: 0;
        font-weight: 400;
        border-bottom: 1px solid #444;
        padding-bottom: 0.5rem;
    }
    .figure-display {
        margin: 1rem 0;
        background-color: #0000008c;
        box-shadow: -5px 5px 30px -1px #000;
    }
    p {
        margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #bababa;
    }
    .waiting-message-wrapper{
        position: relative;
        height: 36px;
        line-height: 36px;
        text-align: center;
      }
      .waiting-message{
        position: absolute;
        width: 100%;
        text-transform: uppercase;
      }
      .waiting-message:nth-child(1){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        animation-direction: alternate-reverse;  
      }
      
      
      .waiting-message:nth-child(2){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        animation-direction: alternate;
      }
      
      @keyframes fade{
          0%,50% {
            opacity: 0;
      }
          100%{
            opacity: 1;
        }
      }
}



