@import '../../../global.scss';

.supply-token {
            
    .figure-display {
        box-shadow: -5px 5px 30px -1px #000;
    }
    .percent-tag {
        position: absolute;
        right: 0;
        top: 0;
        font-family: 'Nanum Gothic Coding', monospace;
        display: inline-block;
        padding: 0.5rem;
        opacity: 0.8;
        font-size: 0.9rem;
        border-radius: 0 0 0 0.5rem;
        @include media('<=1000px') {
            font-size: 0.75rem;
            padding: 0.25rem;
          }
        &.p100 {
            background-color: rgb(8, 56, 0);
        }
        &.p200 {
            
            background-color: rgb(80, 0, 0);
        }
        &.p150 {
            background-color: rgb(100, 85, 0);
        }
    }
    .item-details {
        position: relative;
        padding: 1rem 1rem;
        box-sizing: border-box;
        background-color: #00000063;
        border-radius: 2px;
        margin: 1rem 0;
        color: #bababa;
        
        @include media('<=1000px') {
           font-size: 0.75rem;
           padding: 0.5rem 0.5rem;
         }
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            font-family: 'Nanum Gothic Coding', monospace;
    font-size: 1rem;
    margin: 0.5rem 0;
    @include media('<=1000px') {
        font-size: 0.75rem;
        margin: 0.25rem 0;
      }
    &.not-enough {
        opacity: 0.4;
    }
        }
    }



    .mint-area {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .total {
            text-align: left;
            font-family: 'Nanum Gothic Coding', monospace;
            font-size: 1.2rem;
            color: #c1b083;
            @include media('<=1000px') {
                font-size: 0.9rem;
                 
              }
            &.no-cost {
                opacity: 0.5;
            }
            img {
                width: 32px;
                margin-right: 0.5rem;
                vertical-align: middle;
                opacity: 0.6;
                @include media('<=1000px') {
                    width: 28px;
                     
                  }
            }
            line-height: 32px;
        }
    }
    .mint-button, .pending-mint-button, .expired-mint-button {
        background-color: #4e4631;
        border: 1px solid #746849;
        color: #FFF;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2px;
        transition: border 0.25s, background-color 0.25s;
        box-shadow: 0 0 14px -2px #000;
        @include media('<=1000px') {
            padding: 0.5rem 0.5rem;
             
          }
        &:hover {
            border: 1px solid #94845c;
            background-color: #685d42;
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .pending-mint-button {
        opacity: 0.5;
    pointer-events: none;
        display: inline-block;
        div {
            display: inline-block;
        }
    }
    .expired-mint-button {
        opacity: 0.5;
    pointer-events: none;
        display: inline-block;
        background-color: #222;
        border: 1px solid #333;
        div {
            display: inline-block;
        }
    }


    span {
        &.element-1 {
            background-color: $colorDeath;
        }
        &.element-2 {
            background-color: $colorLife;
        }
        &.element-3 {
            background-color: $colorFire;
        }
        &.element-4 {
            background-color: $colorWater;
        }
        &.element-5 {
            background-color: $colorEarth;
        }
        &.element-6 {
            background-color: $colorAir;
        }
        &.element-7 {
            background-color: $colorMemory;
        }
        &.element-8 {
            background-color: $colorVision;
        }
    }


    li {
        span.element-type {
            width: 12px;
            height: 12px;
            vertical-align: middle;
            display: inline-block;
            border-radius: 100%;
            box-shadow: inset 0px 2px 5px -2px #fff;
            box-sizing: border-box;
            @include media('<=1000px') {
                width: 10px;
            height: 10px;
              }
        }
    }
}


.supply-minting-modal {
   width: 400px;
    .button-bar {
    margin-top: 0.5rem;
    
    }
   @include media('<=1000px') {
    width: 100%;
     
  }
   max-width: 400px !important;
    h2 {
        margin: 0;
        font-weight: 400;
        border-bottom: 1px solid #444;
        padding-bottom: 0.5rem;
        @include media('<=1000px') {
            font-size: 1.25rem;
         }
    }
    .figure-display {
        margin: 1rem 0;
        background-color: #0000008c;
        box-shadow: -5px 5px 30px -1px #000;
    }
    .figure-detail {
        text-align: left;
    }
    p {
        margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #bababa;
    }
    .waiting-message-wrapper{
        position: relative;
        height: 36px;
        line-height: 36px;
        text-align: center;
      }
      .waiting-message{
        position: absolute;
        width: 100%;
        text-transform: uppercase;
      }
      .waiting-message:nth-child(1){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        animation-direction: alternate-reverse;  
      }
      
      
      .waiting-message:nth-child(2){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        animation-direction: alternate;
      }
      
      @keyframes fade{
          0%,50% {
            opacity: 0;
      }
          100%{
            opacity: 1;
        }
      }

      .item-details {
        position: relative;
        padding: 1rem 1rem;
        box-sizing: border-box;
        background-color: #00000063;
        border-radius: 2px;
        margin: 1rem 0;
        color: #bababa;
        
        @include media('<=1000px') {
            padding: 0.5rem;
            margin: 0.5rem 0;
           font-size: 0.75rem;
         }
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            font-family: 'Nanum Gothic Coding', monospace;
    font-size: 1rem;
    margin: 0.5rem 0;
    @include media('<=1000px') {
        font-size: 0.75rem;
      }
    &.not-enough {
        opacity: 0.4;
    }
        }
    }

    .mint-area {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .total {
            text-align: left;
            font-family: 'Nanum Gothic Coding', monospace;
            font-size: 1.2rem;
            color: #c1b083;
            @include media('<=1000px') {
                font-size: 0.9rem;
                 
              }
            &.no-cost {
                opacity: 0.5;
            }
            img {
                width: 32px;
                margin-right: 0.5rem;
                vertical-align: middle;
                opacity: 0.6;
                @include media('<=1000px') {
                    width: 28px;
                     
                  }
            }
            line-height: 32px;
        }
    }
    
 

    span {
        &.element-1 {
            background-color: $colorDeath;
        }
        &.element-2 {
            background-color: $colorLife;
        }
        &.element-3 {
            background-color: $colorFire;
        }
        &.element-4 {
            background-color: $colorWater;
        }
        &.element-5 {
            background-color: $colorEarth;
        }
        &.element-6 {
            background-color: $colorAir;
        }
        &.element-7 {
            background-color: $colorMemory;
        }
        &.element-8 {
            background-color: $colorVision;
        }
    }

    li {
        text-align: left;
        span.element-type {
            width: 12px;
            height: 12px;
            vertical-align: middle;
            display: inline-block;
            border-radius: 100%;
            box-shadow: inset 0px 2px 5px -2px #fff;
            box-sizing: border-box;
        }
    }

    .figure-display {
        box-shadow: -5px 5px 30px -1px #000;
    }
    .percent-tag {
        position: absolute;
        right: 0;
        top: 0;
        font-family: 'Nanum Gothic Coding', monospace;
        display: inline-block;
        padding: 0.5rem;
        opacity: 0.8;
        font-size: 0.9rem;
        border-radius: 0 0 0 0.5rem;
        @include media('<=1000px') {
            font-size: 0.75rem;
            padding: 0.25rem;
          }
        &.p100 {
            background-color: rgb(8, 56, 0);
        }
        &.p200 {
            
            background-color: rgb(80, 0, 0);
        }
        &.p150 {
            background-color: rgb(100, 85, 0);
        }
    }
}
