@import '../../global.scss';

.name-change-page {
    height: 100%;
    overflow-y: scroll;
    .name-change-page-wrapper {
        box-sizing: border-box;
    position: relative;
    z-index: 1000;
    padding: 2rem 2rem;
    max-width: 800px;
    margin: 0 auto;
    @include media('<=tablet') {
        padding: 2rem 1rem;
      }
    }
    
    h1 {
        margin-top:0;
        font-size: 2.5rem;
        font-weight: 400;
        @include media('<=tablet') {
            font-size: 2rem;
          }
    }
    h2 {
        
    font-size: 1.8rem;
    font-weight: 400;
    @include media('<=tablet') {
        font-size: 1rem;
      }
    }
    p {
        color: #bababa;
        font-size: 1.2rem;
        font-weight: 400;
        @include media('<=tablet') {
            font-size: 1rem;
          }
        a {
            color: #837a69;
            font-weight:bold;
        }
        strong {
            color: #837a69;
        }
    }
   .form-error {
       color: #ff3636;
       margin: 1rem 0;
   }
   ul {
       list-style-type: none;
       margin: 0;
       padding: 0;
       color: #bababa;
       margin: 1rem 0;
       li {

           font-size: 1.2rem;
           margin: 0;
           padding: 0 0.5rem 0 0;
           transition: color 0.2s;
           display: grid;
           grid-template-columns: 45px 1fr;
           &:nth-child(even) {background: #000}
           &:nth-child(odd) {background: #111} 
           &:hover {
               background-color: #1c1c1c;
           }       
           @include media('<=tablet') {
            font-size: 1.2rem;
          }
          &:hover {
              color: #fff;
          }
          button {
            font-family: 'Nanum Gothic Coding', monospace;
            text-transform: uppercase;
            color: #fff;
            background-color: transparent;
            border: 0;
            font-size: 1rem;
            display: inline-block;
            width: 100%;
            padding: 0;
            height: 100%;
            cursor: pointer;
            &:disabled {
                opacity: 0.3;
            }
            @include media('<=tablet') {
                font-size: 0.9rem;
              }
          }
          .user-name-option {
              display: block;
              cursor: pointer;
              line-height: 38px;
              position: relative;
              padding: 0.5rem 0;
              &:after {
                  content: '';
                  opacity: 0.5;
                  position: absolute;
                  right: 1rem;
                  top: 50%;
                  width: 10px;
                  height: 10px;
                  border-right: 1px solid #857a60;
                  border-top: 1px solid #857a60;
                  transform: rotate(45deg) translateY(-50%);
                  margin-top: -2px;
              }
          }
          &.username-locked {
            svg {
                margin-top: 1px;
                path {
                    fill: #CCC !important;
                }
            }
          }
          &.username-unlocked {
              svg {
                margin-top: 1px;
                  path {
                      fill: #444 !important;
                  }
              }
          }
       }
   }
    
    .user-select-tools {
        display: grid;
        grid-template-columns: 1fr 1fr;
        span {
            font-size: 1.2rem;
            color: #fff;
            line-height: 34px;
            text-align: right;
            display: block;
        }
        button {
            background-color: transparent;
            border: 1px solid #857a60;
            color: #857a60;
            font-family: 'Nanum Gothic Coding', monospace;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 1rem;
            padding: 0.5rem;
            border-radius: 2px;
        }
    }
}

.approve-name-modal {

    h2 {
        margin: 0;
        font-weight: 400;
        border-bottom: 1px solid #444;
        padding-bottom: 0.5rem;
    }
    p {
        margin-top:0.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        color: #bababa;
    }
    

    
}
