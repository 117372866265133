@import '../../global.scss';

.mintable-list {
    padding-top: 1rem;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr;
        @include media('<=1000px') {
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
         }
        gap: 2rem;
    }
}