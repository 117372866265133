.history-card {
    padding: 0.5rem 1rem;
    .history-card-heading {
        //padding-bottom: 0.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        line-height: 1.6rem;
        .history-date {
            color: #666;

        }
        .history-user {
            color: #bababa;
        font-family: 'Cormorant', serif;
        font-size: 1.1rem;
        text-align: right;
        }
    }
    .history-card-detail {
        line-height: 1.6rem;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        text-align: left;
        color: #888;
        .history-type {

        }
        .history-element {

        }
        .history-amount {
            text-align: right;
            color: #c0b999d9;
        }
    }
    .total-content {
        display: grid;
        grid-template-columns: 40px 1fr 60px;
        text-align: right;
        line-height: 1.4rem;
        cursor: pointer;
    }
    &.is-element {
        .total-content {
            display: grid;
            grid-template-columns: 40px 1fr 80px 80px;
        }
    }
    .sub-content-wrapper {
        //display: none;
        height: 0px;
        position: relative;
        overflow: hidden;
        transition: height 0.2s ease-in;
    }
    .sub-content {
        padding: 1rem 0;
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: 1fr 1fr;
        grid-auto-columns: minmax(0, 1fr);
        text-align: center;
        gap: 1rem;
        .element-amount {
            font-size: 1rem;
        }
        .element-rank {
            color: #999;
            font-size: 0.85rem;
        }
    }
    .element-label {
        margin-bottom: 0.5rem;
        font-size: 0.75rem;
        text-transform: uppercase;
        display: block;
        color: #666;
    }
    .total-rank {
        text-align: left;
        color: #c0b999d9;
    }
    .align-left {
        text-align: left;
    }
    .rank-card-username {
        color: #bababa;
        font-family: 'Cormorant', serif;
        font-size: 1.1rem;
        text-align: left;
    }
    .rank-percent {
        color: #c0b999d9;
    }
    .rank-amount {
        color: #c0b999d9;
    }
    .unranked {
        color: #444;
    }
        
}