@import '../../../../global.scss';

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.circle-blood-pact {
    width: 100%;
    height: 0;
    padding-top: 100%;
    .circle-slot {
        position: relative;
        width: $figureMedium;
        height: $figureMedium;
       
        @include media('<=1000px') {
            width: $figureSmall;
            height: $figureSmall;
        }
       
         &.open {
            clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 100% 100%, 97% 97%, 3% 97%, 3% 3%, 97% 3%, 97% 97%, 100% 100%
            );
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
            }
            &:after {
                content: '';
                height: 200%;
                top: -50%;
                left: -50%;
                width: 200%;
                position: absolute;
                animation: rotate 5s linear infinite;
                opacity: 0.5;
                background: rgb(255,255,255);
                background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33%, rgba(255,255,255,0.5018382352941176) 50%, rgba(255,255,255,0) 66%, rgba(255,255,255,0) 100%);
            }
         }
         .figure-display {
            box-shadow: -5px 5px 30px -1px #000;
         }
    }

   
    .circle-display {
        position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
        background-image: url('../../../../image/circle-background.png');
    }
    .circle-top {
        position: absolute;
    top: 0;
    left: 16.5%;
    right: 16.5%;
    padding-top: 30.5%;
    }
    .board-slot {
        position: absolute;
        //opacity: 0.1;
        button {
            height: 36px;
            width: 36px;
            padding: 0;
            border: 0;
            opacity: 0.75;
            background-color: #000;
            border-radius: 100%;
            top: -18px;
            right: -18px;
            position: absolute;
            cursor: pointer;
            @include media('<=1000px') {
                height: 24px;
                width: 24px;
                top: -12px;
                right: -12px;
                
            }
            svg {
                width: 24px;
                height: 24px;
                @include media('<=1000px') {
                    width: 16px;
                    height: 16px;
                }
                path {
                    fill: #ccc;
                }
            }
        }
        .slot-hit-box {
            &.open {
                cursor: pointer;
            }
        }
    }
    .board-slot-0 {
        bottom: 0;
        left: 0;
        transform: translate3d(-50%, 50%, 0);
    }
    .board-slot-1 {
        bottom: 0;
        right: 0;
        transform: translate3d(50%, 50%, 0);
    }
    .board-slot-2 {
        bottom: 12%;
    left: 50%;
    transform: translate3d(-50%, 50%, 0);
    }
    .board-slot-3 {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}

.slot-power {
    position: absolute;
    width: 300%;
    height: 300%;
    left: -100%;
    top: -100%;
    opacity: 0.2;

    &.element-1 {
        background-image: radial-gradient($colorDeath 0%, transparent 70%,);
    }
    &.element-2 {
        background-image: radial-gradient($colorLife 0%, transparent 70%,);
    }
    &.element-3 {
        background-image: radial-gradient($colorFire 0%, transparent 70%,);
    }
    &.element-4 {
        background-image: radial-gradient($colorWater 0%, transparent 70%,);
    }
    &.element-5 {
        background-image: radial-gradient($colorEarth 0%, transparent 70%,);
    }
    &.element-6 {
        background-image: radial-gradient($colorAir 0%, transparent 70%,);
    }
    &.element-7 {
        background-image: radial-gradient($colorMemory 0%, transparent 70%,);
    }
    &.element-8 {
        background-image: radial-gradient($colorVision 0%, transparent 70%,);
    }
}
