@import '../../global.scss';

.page-header {
    
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 2rem;
        @include media('<=900px') {
            font-size: 1.6rem;
        }
}