@import '../../global.scss';

.mastery-bar {
    display: grid;
    grid-template-columns: 75px 1fr 50px;
    .mastery-bar-pane {
        position: relative;
        img {
            position: absolute;
            width: 100px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
        }
        &:after {
            content: '';
            box-shadow: 0 0 18px 12px #000;
            width: 60%;
            height: 50px;
            right: 0;
            position: absolute;
            z-index: 400;
        }
    }
    .mastery-bar-track {
        background-image: url('../../image/mastery-bar-bg.png');
        height: 50px;
        background-size: 100% 100%;
        box-shadow: 4px 1px 21px -1px #000;
        padding: 4px 0;
        box-sizing: border-box;
        position: relative;
        &:after {
            content: '';
            top: 4px;
            left: 0;
            right: 0;
            bottom: 4px;
            position: absolute;
            box-shadow: inset 0 0 10px 1px #000;
            border: 2px solid #000;
            z-index: 200;
        }
        
        .texture-overlay {
            
                
                position: absolute;
                left: 0;
                top: 4px;
                bottom: 4px;
                width: 100%;
                mix-blend-mode: multiply;
                opacity: 0.5;
                z-index: 100;
                background-size: cover;
                &:before {
                    content: '';
                    top: 4px;
                    left: 0;
                    right: 0;
                    bottom: 4px;
                    position: absolute;
                    background: rgb(255,255,255);
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 27%, rgba(255, 255, 255, 0) 65%);
                    z-index: 150;
                }
        }
        .mastery-bar-segments {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow:column;
            height: 42px;
            position: relative;
            background-image: url('../../image/stone-darkest.png');
            
            
           
            .segment {
                text-align: center;
                position: relative;
                &:after {
                    content: '';
                    top: 0;
                    right: -2px;
                    height: 100%;
                    width: 0px;
                    border-left: 2px solid #eaeaea;
                    border-right: 2px solid #000000;
                    position: absolute;
                    display: block;
                    opacity: 0.1;
                    z-index: 500;
                
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                .segment-vial {
                    height: 100%;
                }
            }
        }
    }
    
    .mastery-bar-cap {
        height: 50px;
        position: relative;
        img {
            position: absolute;
            left: -17px;
            top: 0;
            height: 50px;
            z-index:300;
        }
    }
}




$glowHeight: 25px;
.mastery-bar .mastery-bar-track {
.texture-overlay {
    &.element-1 {
        mix-blend-mode: normal;
                opacity: 0.2;
        background-image: url('../../image/exp-overlay-element-1.png');
    }
    &.element-2 {
        opacity: 0.9;
        background-image: url('../../image/exp-overlay-element-2.png');
    }
    &.element-3 {
        
        background-image: url('../../image/exp-overlay-element-3.png');
    }
    &.element-4 {
        background-image: url('../../image/exp-overlay-element-4.png');
    }
    &.element-5 {
        background-image: url('../../image/exp-overlay-element-5.png');
    }
    &.element-6 {
        background-image: url('../../image/exp-overlay-element-6.png');
    }
    &.element-7 {
        background-image: url('../../image/exp-overlay-element-7.png');
    }
    &.element-8 {
        mix-blend-mode: luminosity;
    opacity: 0.3;
        background-image: url('../../image/exp-overlay-element-8.png');
    }
}
}
.glow {
    &.glow-1 {
        box-shadow: 0 0 $glowHeight 5px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 $glowHeight 5px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 $glowHeight 5px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 $glowHeight 5px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 $glowHeight 5px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 $glowHeight 5px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 $glowHeight 5px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 $glowHeight 5px $colorVision;
    }
}

.glow-start, .glow-start {
    &.glow-1 {
        box-shadow: 0 0 80px 20px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 80px 20px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 80px 20px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 80px 20px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 80px 20px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 80px 20px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 80px 20px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 80px 20px $colorVision;
    }
}

.vial-1 {
    background-color: $colorDeath;
}
.vial-2 {
    background-color: $colorLife;
}
.vial-3 {
    background-color: $colorFire;
}
.vial-4 {
    background-color: $colorWater;
}
.vial-5 {
    background-color: $colorEarth;
}
.vial-6 {
    background-color: $colorAir;
}
.vial-7 {
    background-color: $colorMemory;
}
.vial-8 {
    background-color: $colorVision;
}

