@import '../../../../global.scss';



$widthLarge: 42px;
$heightLarge: 400px;

$widthMedium: 28px;
$heightMedium: 200px;


.vertical-vial-wrapper {
    .vial-symbol {
        width: $widthLarge;
        margin: 0 auto;
        cursor: pointer;
        margin-bottom: 0.5rem;
        @include media('<=tablet') {
            width : 36px;
            margin-bottom: 0.25rem;
        }
        img {
            transition:  opacity 0.2s;
            opacity: 0.3;
            width: $widthLarge;
            height: $widthLarge;
            @include media('<=tablet') {
                width : 36px;
                height: 36px;
            }
        }
    }
    .vial-label {
        font-family: 'Nanum Gothic Coding', monospace;
        font-size: 0.9rem;
        text-align: center;
        line-height: 2rem;
        color: #bababa;
        cursor: pointer;
    }
    &.complete {
        .vial-symbol {
            cursor: not-allowed;
        }
        .vial-label {
            cursor: not-allowed;
        }

    }
    
}
.vertical-vial {
    width : $widthLarge;
    height: $heightLarge;
    position: relative;
    margin: 0 auto;
    @include media('<=tablet') {
        width : $widthMedium;
        height: $heightMedium;
    }
    
    .contents {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.65;
       
    }
    .contents-mask {
        z-index: 500;
        position: relative;
        width : 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        border-radius: 39px;
        .glow {
            position: absolute;
            bottom: 0%;
            left: 0;
            height: 0px;
            width: 100%;
            transition: bottom 0.5s ease-out;
            
        }
        .glow-start {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0px;
            width: 100%;
            transition: opacity 0.25s;
        }
        .measurements {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            grid-template-columns: 1fr;
            grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
            -webkit-mask-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 40%,  rgba(0,0,0,1) 100%);
            mask-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 40%,  rgba(0,0,0,1) 100%);

            div {
                box-sizing: border-box;
                border-bottom: 1px solid #000;
                border-top: 1px solid #fff;
                opacity: 0.2;
                height: 10%;
                &:last-child {
                    border-bottom: 0px;
                }
            }
        }
        .balance-flask {
            height: 100%;
            font-size: 0;
            opacity: 0.5;
            white-space: nowrap;
            position: relative;
            div {
                position: absolute;
                bottom:0;
                left: 0;
                box-shadow: inset 0 0 7px 1px #ffffff45;
                display: inline-block;
                opacity: 0.6;
                width: 100%;
                transition: height 0.5s ease-out;
                height: 0%;
                overflow: hidden;
                &:after {
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    box-shadow: 0 0 20px 10px #ffffff7d;
                    display: block;
                    height: 2px;
                    background-color: white;



                }
            }
        }
    }
    .vial-bg {
        z-index: 1;
    }

    .vial-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        z-index: 300;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 57.5px 1fr  57.5px;
        @include media('<=tablet') {
            width : $widthMedium;
            grid-template-rows: 38.33px 1fr  38.34px;
        }
        .vial-top {
            background-image: url('../../../../image/global-2-top.png');
            background-size: contain;
        }
        .vial-bottom {
            background-image: url('../../../../image/global-2-bottom.png');
            background-size: contain;
        }
        .vial-center {
            background-image: url('../../../../image/global-2-center.png');
            background-size: 100% 100%;
            
        }
    }

    &.complete {
        .contents-mask {
            cursor: not-allowed;
        }
        .balance-flask {
            div {
                &:after {
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    box-shadow: none;
                    display: block;
                    height: 0;
                    background-color: transparent;
                }
            }
        }
    }
}



$glowHeight: 25px;

.glow {
    &.glow-1 {
        box-shadow: 0 0 $glowHeight 5px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 $glowHeight 5px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 $glowHeight 5px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 $glowHeight 5px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 $glowHeight 5px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 $glowHeight 5px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 $glowHeight 5px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 $glowHeight 5px $colorVision;
    }
}

.glow-start, .glow-start {
    &.glow-1 {
        box-shadow: 0 0 80px 20px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 80px 20px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 80px 20px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 80px 20px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 80px 20px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 80px 20px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 80px 20px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 80px 20px $colorVision;
    }
}

.vial-1 {
    background-color: $colorDeath;
}
.vial-2 {
    background-color: $colorLife;
}
.vial-3 {
    background-color: $colorFire;
}
.vial-4 {
    background-color: $colorWater;
}
.vial-5 {
    background-color: $colorEarth;
}
.vial-6 {
    background-color: $colorAir;
}
.vial-7 {
    background-color: $colorMemory;
}
.vial-8 {
    background-color: $colorVision;
}

