.experiment-page {
    height: 100%;
    position: relative;
    overflow: hidden;
    .experiment-nav {
        height: 30px;
    }
    .experiment-page-wrapper {
        height: 100%;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        padding-top: 30px;
    }
}