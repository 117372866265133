@import '../../global.scss';

.task-rewards-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 2rem;
    @include media('<=1200px') {
        padding: 2rem 1rem;
    }
    h1 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 2rem;
    }

    h2 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        line-height: 2.4rem;
    }
    .task-rewards-page-content {
        position: relative;
        p {
            color: #bababa;
        }
    }
    .reward-instructions {
        margin: 1rem 0 2rem 0;
        background-color: #111;
        padding: 1rem;
        font-family: 'Nanum Gothic Coding', monospace;
        color: #ccc;
        border: 1px solid #5e5847;
        opacity: 0.7;
        p {
            margin: 0;
            line-height: 1.4rem;;
        }
    }
    .task-reward-navigation {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            font-size: 0;
            li {
                font-size: 1rem;
                display: inline-block;
                margin-right: 0.5rem;
                button {
                    padding: 0.5rem 0;
                    
                    border: 0;
                    outline: none;
                    background-color: transparent;
                    color: #bababa;
                    font-family: 'Cormorant', serif;
                    font-size: 1.2rem;
                    @include media('<=tablet') {
                        font-size: 1rem;
                     }
                    &:hover {
                        color: #fff;
                        cursor: pointer;
                    }
                    &.task-reward-selected {
                        color: #a19679;
                    }
                }
            }
        }
    }
    .task-menu {
        position: relative;
        margin-bottom: 1rem;
        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            background-image: url('../../image/border-bottom.png');
            display: block;
            width: 100%;
            opacity: 0.5;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 0;
            li {
                display: inline-block;
                font-size: 1rem;
                a {
                    display: block;
                    padding: 1rem 1rem;
                    font-family: 'Nanum Gothic Coding', monospace;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #888;
                    transition: color 0.25s ease-in;
                    font-size: 1.2rem;
                    &.active {
                        color: #a19679;
                    }
                    &:hover {
                        color: #fff;
                    }
                    @include media('<=tablet') {
                        font-size: 1rem;
                        padding: 0.75rem;
                     }
                }
                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    

    .tier-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 3rem;
        @include media('<=1000px') {
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
         }
        .reward-token {
            position: relative;
            .prospective {
                opacity: 0;
                position: absolute;
                top: -4px;
                right: 0;
                background-color: #5e5847;
                padding: 0.25rem 0.5rem;
                border-radius: 5px;
                font-size: 0.9rem;
                font-weight: 500;
                transition: opacity 0.25s ease-in;
                color: #bababa;
                @include media('<=tablet') {
                    font-size: 0.75rem;
                    top: 0px;
                    padding: 0.25rem 0.25rem;
                    top: auto;
                    bottom: 0;
                 }
            }
            &.current-user {
                //background-color: #a19679;
                .prospective {
                    
                    opacity: 1;
                }
            }
            .reward-rank {
                position: relative;
                text-transform: uppercase;
                font-size: 1.2rem;
                font-family: 'Nanum Gothic Coding', monospace;
                @include media('<=tablet') {
                    font-size: 0.9rem;
                 }
                margin-bottom: 1rem;
                color: #a19679;
                //display: grid;
                grid-template-columns: 1fr 2fr;
                .rank-number {

                }
                .tier-number {
                    //text-align: right;
                    opacity: 0.7;
                    @include media('<=tablet') {
                        display: block;
                     }
                }
            }
            img {
                width: 100%;
            }
            .tier-title {
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                text-transform: uppercase;
                
                
            }
            
        }
    }


    .progress-rewards {
        .progress-rewards-bar {
            display: grid;
            grid-template-columns: 3fr 1fr 1fr;
        }
        
    }

    .reward-rotation-display {
        display: grid; 
        gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @include media('<=1000px') {
            gap: 1rem;
            grid-template-columns: 1fr 1fr;
        }
    }

    .figure-rotation {
        width: 100%;
        
        padding-top: 100%;
        position: relative;
        .reward-token {
          

        }


        .reward-token {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.fadecycle5 > * {
    opacity: 0;
}

// Animation settings
$totalTime: 10s;
$items: 4;
$animationSpeed: 1;

// Calculate animation time in seconds (how long the fade lasts)
$animationTime: 0s + $totalTime / ($items * $animationSpeed * 2);
// Calculate show time in seconds (how long the element is displayed)
$showTime: (0s + $totalTime - ($animationTime * $items)) / $items;

// Set animation for each element
@for $i from 1 through $items {
  .fadecycle5 > *:nth-child(#{$i}) {
    // Delay is increased for each item
    // Starting with an offset of -$animationTime so the first element is displayed on load
    $delay: -$animationTime + ($animationTime + $showTime) * ($i - 1);
    animation: fadeinout $totalTime linear $delay infinite;
  }
}

// Calculate percentages of the display time for keyframes
$animationPercentage: 0% + 100 * (($animationTime / $totalTime));
$showPercentage: 0% + 100 * ($showTime / $totalTime);

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  #{$animationPercentage},
  #{$animationPercentage + $showPercentage} {
    opacity: 1;
  }
  #{$showPercentage + $animationPercentage * 2},
  100% {
    opacity: 0;
  }
}


