@import './include_media';

$breakpoints: (
  'phone': 688px,
  'phone-large' : 800px,
  'tablet': 900px,
  'desktop': 1333px,
  'desktop-large': 2000px
); 


$colorDeath : #000;
$colorLife : #fff;
$colorFire :  rgb(182, 0, 0);
$colorWater : rgb(56, 63, 163);
$colorEarth : rgb(13, 80, 22);
$colorAir : rgb(122, 122, 122);
$colorMemory : rgb(182, 74, 2);
$colorVision : rgb(73, 7, 85);


$figureLarge: 256px;
$figureMedium: 192px;
$figureSmall: 92px;