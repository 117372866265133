@import '../../global.scss';

.total-progress {
    display: grid;
    grid-template-columns: 1fr 46px;
    opacity: 0.7;
    position: relative;
    font-family: 'Nanum Gothic Coding', monospace;
        font-size: 0.9rem;
        line-height: 1.5rem;
        text-align: left;
        column-gap: 0.5rem;
        color: #bab1a0;
    .total-progress-container {
        top: 50%;
    transform: translateY(-50%);
        box-shadow: 0 0 16px -1px #000;
        width: 100%;
        //position: relative;
        height: 10px;
        background-color: #212121b3;
    overflow: hidden;
    border-radius: 18px;
    position: relative;
        .total-progress-bar {
            position: absolute;
            border-radius: 18px;
            box-shadow: 0 0 27px 5px #000;
            left: 0;
            top: 0;
            width: 0%;
            transition: width 0.25s ease-in;
            height: 100%;
            background-color: #4a4438;
            box-sizing: border-box;
            border-top: 2px solid #8c816c;
            
        }
    }
}