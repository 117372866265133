@import '../../../global.scss';

.first-epoch {
    
    overflow: hidden;
    position: relative;
    .epoch-dates {
        text-align: center;
        color: #666;
        font-size: 1.6rem;
        @include media('<=phone') {
            font-size: 1.2rem;
          }
    }

    .epoch-gallery {
        position: relative;
        z-index: 500;
        max-width: 1200px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 2rem;
        @include media('<=phone') {
            padding: 0 1rem;
          }
        ul {
            
            padding: 0;
            margin: 0;
            list-style-type: none;
            font-size: 0;
            display: grid;
            gap: 1.5rem;
            margin-bottom: 2rem;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            @include media('<=1200px') {
                grid-template-columns: 1fr 1fr 1fr;
              }
            @include media('<=tablet') {
                grid-template-columns: 1fr 1fr 1fr;
              }
              @include media('<=phone') {
                grid-template-columns: 1fr 1fr;
                gap: 0.75rem;
              }
            li {
                .gallery-figure-detail {
                    padding-top: 0.5rem;
                    font-size: 1rem;
                    display: grid;
                    grid-template-columns: 1fr 40px;
                    position: relative;
                    z-index: 200;
                    @include media('<=phone') {
                        font-size: 0.75rem; 
                    }
                    h2 {
                        font-weight: 400;
                        font-size: 1.2rem; 
                        text-align: left;
                        margin: 0;
                        @include media('<=phone') {
                            font-size: 0.9rem; 
                        }
                    }
                    .artist {
                        color: #bababa;
                    }
                    .total-editions {
                        text-align: right;
                    }
                }
                
                
                .gallery-figure-image {
                    
                box-shadow: 1px 1px 36px 1px #000;
                z-index: 100;
                    
                }
                
            }
        }
    }
    .part-1 {
        
        position: relative;
        box-sizing: border-box;
        
        .intro-section {
            p {
                font-size: 1.4rem;
                text-align: center;
                margin-top: 0;
                margin-bottom: 2rem;
                @include media('<=phone') {
                    font-size: 1rem; 
                }
            }
        }
        .intro {
            text-align: center;
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 0;
            margin-top: 0;
            @include media('<=phone') {
                font-size: 1.35rem; 
                display: block;
            }
            .sub-title {
                font-size: 2rem;
                display: block;
                @include media('<=phone') {
                    display: block;
                    font-size: 1.35rem; 
                }
            }
            
        }
        
        
        .part-content {
            max-width: 1200px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 2rem;
            position: relative;
            z-index: 400;
            
            margin: 0 auto;
            padding-top: 2rem;
        }
       
    }
    .timeline {
        padding: 0 3rem;
        position: relative;
        .figure-detail {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            color: #777;
            font-family: 'Nanum Gothic Coding', monospace;
                max-width: 300px;
                text-shadow: 1px 1px #000;
            span {
                text-align: center;
                
                &:last-child {
                    text-align: right;
                }
                &:first-child {
                    text-align: left;
                }
            }
            
        }
        .timeline-item {

            max-width: 1200px;
            margin: 2rem auto;
            position: relative;
            z-index: 400;
            .timeline-content {
                display: grid;
            grid-template-columns: 1fr 1fr;
            p {
                font-size: 1.2rem;
                font-weight: 400;
                color: #bababa;
            }
            a {
                color: #f0dcb1;
                font-size: 1.2rem;
                text-decoration: none;
            }
                .timeline-content-pane {
                    padding: 0 3rem 0 0;
                    box-sizing: border-box;
                    &:last-child {
                        margin-top: 25%;
                        padding: 0 0 0 3rem;
                        h2 {
                            position: relative;
                            padding-bottom: 1rem;
                            &:after {
                                content: '';
                                width: 100%;
                                padding-left: 3rem;
                                position: absolute;
                                left: -3rem;
                                bottom: 0;
                                height: 2px;
                                background: rgb(255,255,255);
                                background: linear-gradient(90deg, rgba(255,255,255,0.9976365546218487) 50%, rgba(255,255,255,0) 100%);
                                //border: 1px solid #ccc;
                            }
                        }
                    }
                    &:first-child {
                        
                        h2 {
                            position: relative;
                            padding-bottom: 1rem;
                            &:after {
                                content: '';
                                width: 100%;
                                padding-right: 3rem;
                                position: absolute;
                                right: -3rem;
                                bottom: 0;
                                height: 2px;
                                background: rgb(255,255,255);
                                background: linear-gradient(270deg, rgba(255,255,255,0.9976365546218487) 50%, rgba(255,255,255,0) 100%);
                                
                            }
                        }
                    }
                    .figure-image {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
            }
            
            .hint-detail {
                padding: 1rem;
                background-color: #ffbe2f1a;
                box-sizing: border-box;
                border-radius: 4px;
                p {
                    color: #f0dcb1;
                    font-size: 1.1rem;
                    margin: 0 0 1rem 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            h2 {
                margin-top: 0.5rem;
                text-align: left;
                font-size: 1.8rem;
                font-weight: 400;
                .artist {
                    color: #bababa;
                    display: block;
                    font-size: 1.4rem;
                }
            }
            .letter-detail {
                text-align: center;
                position: relative;
                img {
                    width: 80%;
                    position: absolute;
                    bottom: 10%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}