@import '../../global.scss';

.about-page {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    .return-link {
        color: #bababa;
        text-transform: uppercase;
        text-decoration: none;
        opacity: 0.5;
    }
    .about-wrapper {
        position: relative;
        z-index: 1000;
        padding: 2rem 2rem;
        max-width: 800px;
        margin: 0 auto;
        
    }
    @include media('<=tablet') {
        padding: 2rem 1rem;
      }
    h1 {
        font-size: 2.5rem;
        font-weight: 400;
    }
    h2 {
        margin-top:3rem;
        font-size: 1.8rem;
        font-weight: 400;
    }
    p {
        color: #bababa;
        font-size: 1.2rem;
        font-weight: 400;
        @include media('<=tablet') {
            font-size: 1rem;
          }
        a {
            color: #837a69;
        }
        strong {
            color: #837a69;
        }
    }
    .ghost-button {
        color: #837a69;
        display: inline-block;
        padding: 0.5rem 1rem;
        text-decoration: none;
        border: 1px solid #837a69;
        font-weight: 600;
        text-align: center;
    }
    .background-image {
        z-index: 0;
        pointer-events: none;
        position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.075;
    height: 100%;
    img {
        height: 100%;
    }
    }
}