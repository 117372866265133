.gallery-figure-image {
    
    
}
.gallery-figure-detail {
    h2 {
        overflow: hidden;
    text-overflow: ellipsis;
    }
    
}