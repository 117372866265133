.modal-container {
    position: relative;
    min-width: 300px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background-image: url('../../image/stone-dark.png');
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0 0 42px 16px #000;

    &.loading {
        pointer-events: none;
        
        .modal-button-bar {
            opacity: 0.25;
        }
    }
}