@import '../../global.scss';

.user-page {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-image: url('../../image/stone-dark.png');
    &:before {
        content: "";
        
      //background-size: contain;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      //opacity: 0.35;
      box-shadow: inset 0 0 147px 17px #000;
      pointer-events: none;
    }
    .elements {
      padding: 2rem;
      gap: 0.5rem;
      position: absolute;
      bottom: 0;
      right: 0;
      display: grid;
      grid-template-columns: 1fr ;
    }
    .connect-area {
      width: 75%;
    height: 75%;
    background-color: transparent;
    position: absolute;
    z-index: 1100;
    border: 0;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%, 10px);
    cursor: pointer;
    }
    .circle-connect {
      width: 100%;
      max-width: 724px;
      
    position: absolute;
    z-index: 1100;
    
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
      img {
        width: 100%;
        
      }
    }
    .circle-options {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      button {
        font-family: 'Cormorant', serif;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;
        text-align: center;
      font-size: 1.6rem;
      line-height: 64px;
      color: #bababa;
      margin: 0 auto;
      @include media('<=1000px') {
        font-size: 1.4rem;
        line-height: 48px;
      }
      }
    }
  }



  .upgrade-circle-details {
    position: absolute;
    left:0;
    margin-top: 1rem;
    @include media('<=1000px') {
      margin-top: 0.1rem;
      
    }
    img {

      margin-top: 1rem;
      width: 100%;
    }
  }
  .supply-minting-modal {
    .circle-prices {
      ul {
        
        
        
      }
      .price-option {
        position: relative;
        padding: 0.5rem 0;
        cursor: pointer;
        padding-left: 2rem;
        border-top: 1px solid #413721;
        &:last-child {
          border-bottom: 1px solid #413721;
        }
        .check-box {
          background-color: #000;
          position: absolute;
          left: 0.2rem;
          top: 0.9rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
