.forge-page {
    background-image: url('../../image/stone-dark.png');
    min-height: 100%;
    
    .forge-page-wrapper {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        h1 {
            margin: 0;
        }
    }
}