@import '../../../global.scss';

.global-vials {
    //background-image: url('../../../image/stone-dark.png');
    background-color: black;
    position: relative;
    z-index: 100;
    .experiment-content {
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 2rem;
        row-gap: 1rem;
        padding: 2rem;
        .global-vial-wrapper {
            grid-row: 1 / 3;
            grid-column: 2 / 3;
        }
        
        @include media('<=tablet') {
            grid-template-columns: 1fr;
            //grid-auto-flow: sparse;
            gap: 1rem;
            padding: 1rem;
            .global-vial-wrapper {
                grid-row: auto;
                grid-column: auto;
            }
        }
        
    }
    .complete-tag {
        margin: 1rem 0;
        font-size: 1rem;
        display: inline-block;
        font-family: 'Nanum Gothic Coding', monospace;
        background-color: #4a4438;
        box-sizing: border-box;
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
        opacity: 0.7;
        letter-spacing: 2px;
        @include media('<=tablet') {
            font-size: 0.85rem;
        }
    }
    .total-progress {
        margin: 1rem 0;
        @include media('<=tablet') {
            width: 50%;
        }
    }
    .task-description {
        // padding: 2rem;
        // @include media('<=tablet') {
        //     padding: 1rem;
        // }
        h1 {
            margin: 0;
            font-size: 2rem;
            font-weight: 400;
            opacity: 1;
            transition: opacity 5s ease-in;
            @include media('<=tablet') {
                font-size: 1.8rem;
            }
        }
        p {
            font-size: 1.2rem;
            margin: 0 0 1rem 0;
            color: #bababa;
            @include media('<=tablet') {
                font-size: 1rem;
            }
            &:last-child {
                margin: 0;
            }
        }
        .task-detail {
            font-size: 1.2rem;
            color: #bababa;
            text-transform: uppercase;
            opacity: 0.7;
        }
        .user-ranking {
            
            font-size: 1.2rem;
            display: grid;
            grid-template-columns: 1fr 60px 100px;
            line-height: 1.2rem;
            .user-name {
                font-size: 1.2rem;
                
            }
            .user-rank {
                font-size: 1rem;
                font-family: 'Nanum Gothic Coding', monospace;
                text-align: right;
                .user-rank-number {
                    color: #888;
                }
            }
            .user-additional {
                font-size: 1rem;
                font-family: 'Nanum Gothic Coding', monospace;
                text-align: right;
                color: #888;
            }
        }
    }
    &.complete {
        .task-description {
            h1 {
                opacity: 0.5;
            }
        }
    }
    .global-vial-wrapper {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        z-index: 300;
        // padding: 2rem;
        // @include media('<=tablet') {
        //     padding: 1rem;
        // }
    }

    
    &:after {
        background-image: var(--task-background-image);
        background-size: cover;
        background-position: center center;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.1;
        pointer-events: none;
        display: block;
        z-index: -1;
    }

    .task-ranking {
        .rank-static {
            position: relative;
            padding: 0.5rem;
                border: 0px;
                outline: none;
                background-color: transparent;
                color: #CCC;
                width: 100%;
                box-sizing: border-box;
                font-family: inherit;
                font-size: 1rem;
                text-transform: uppercase;
                text-align: left;
                span {
                    position: absolute;
                    transition: transform 0.3s ease-out;
                    content: '';
                    width: 100%;
                    height: 2px;
                    display: block;
                    bottom: 0;
                    background: #745b2f;
                    z-index: 400;
                    background: linear-gradient(
                90deg
                , rgba(116, 91, 47, 0) 0%, #745b2f 30%, #745b2f 70%, rgba(116, 91, 47, 0) 100%);
                    
                }
        }
        .rank-toggle {
            position: relative;
            span {
                position: absolute;
                transition: transform 0.3s ease-out;
                content: '';
                width: 50%;
                height: 2px;
                display: block;
                bottom: 0;
                background: #745b2f;
                z-index: 400;
                background: linear-gradient(
            90deg
            , rgba(116, 91, 47, 0) 0%, #745b2f 30%, #745b2f 70%, rgba(116, 91, 47, 0) 100%);
                
            }
            button {
                padding: 0.5rem;
                border: 0px;
                outline: none;
                background-color: transparent;
                cursor: pointer;
                color: #CCC;
                width: 50%;
                box-sizing: border-box;
                font-family: inherit;
                font-size: 1rem;
                text-transform: uppercase;
            }
        }
        .task-rank-table {
            overflow: hidden;
            border-radius: 2px;
            box-shadow: 0 0 15px -4px #000;
        }
        ol {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                background-color: #1a1a1a;
                &:nth-child(odd) {
                    background-color: #111;
                }
                
            }
        }
        .rank-row {
            padding: 0.33rem 0.5rem;
            font-size: 1rem;
            display: grid;
            grid-template-columns: 60px 1fr 60px;
            line-height: 1.5rem;
            box-sizing: border-box;
            @include media('<=tablet') {
                font-size: 1rem;
                line-height: 1.2rem;
                padding: 0.5rem;
            }
            &.current-user {
                background-color: #3b372f;
                box-shadow: inset 0px -5px 25px -17px #bababa;
                .rank-number {
                    color: #fff;
                }
                .rank-user {
                    color: #fff;
                }
                .rank-stat {
                    color: #fff;
                }
            }
            .rank-number {
                font-family: 'Nanum Gothic Coding', monospace;
                color: #888;
                font-size: 0.9rem;
                @include media('<=tablet') {
                   font-size: 0.75rem;
                }
            }
            .rank-user {
                color: #bababa;
            }
            .rank-stat {
                font-family: 'Nanum Gothic Coding', monospace;
                color: #888;
                text-align: right;
                font-size: 0.9rem;
                @include media('<=tablet') {
                    font-size: 0.75rem;
                 }
            }
        }
        .rank-header {
            font-size: 0.85rem;
            line-height: 1rem;
            font-family: 'Nanum Gothic Coding', monospace;
            padding: 0.5rem;
            display: grid;
            grid-template-columns: 60px 1fr 60px;
            color: #777;
            box-sizing: border-box;
            background-color: #0c0c0c;
            text-transform: uppercase;
            .rank-header-total {
                text-align: right;
            }
        }
    }

    .task-section-heading {
        margin-top: 1rem;
        position: relative;
        h2 {
            font-weight: 400;
            margin: 0;
            padding-bottom: 0.5rem;
            //margin-bottom: 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            color: #ccc;
        }
        span {
            position: absolute;
            transition: transform 0.3s ease-out;
            content: '';
            width: 100%;
            height: 2px;
            display: block;
            bottom: 0;
            background: #745b2f;
            z-index: 400;
            background: linear-gradient(
        90deg
        , rgba(116, 91, 47, 0) 0%, #745b2f 30%, #745b2f 70%, rgba(116, 91, 47, 0) 100%);
            
        }
        .task-section-nav {
            position: absolute;
            top: -2px;
            right: 0;
            a {
                font-family: 'Nanum Gothic Coding', monospace;
                text-transform: uppercase;
                font-size: 0.9rem;
                //line-height: 1.8rem;
                color: #777;
                margin-left: 1rem;
                text-decoration: none;
                display: inline-block;
            }
        }
        
    }

    .user-task-rewards {
        padding: 0 1rem 1rem;
        max-width: 1200px;
        margin: 0 auto 0;
        @include media('<=tablet') {
            margin-top: 1rem;
         }
       
        ul {
            background-color: #00000063;
            
            list-style-type: none;
            margin: 0;
            padding: 2rem;
            border-radius: 3px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap : 2rem;
            @include media('<=1100px') {
                grid-template-columns: 1fr 1fr 1fr;
                gap : 1rem;
                padding: 1rem;
             }
            @include media('<=tablet') {
                grid-template-columns: 1fr 1fr;
             }
            &.rewards-empty {
                grid-template-columns: 1fr; 
                color: #666;
                line-height: 1.4rem;
                a {
                    color: #a19679;
                    text-decoration: none;
                    white-space: nowrap;
                }
            }
            li {
                font-family: 'Nanum Gothic Coding', monospace;
                .reward-token-summary {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .task-menu {
        //background-color: #000000b3;
        //padding: 0 1rem;
        padding: 0.5rem 0 0;
        ul {
            max-width: 1200px;
            margin: 0 auto;
            list-style-type: none;
            
            padding: 0;
            font-size: 0;
            li {
                
                display: inline-block;
                text-transform: uppercase;
                
                font-weight: 400;
                color: #bababa;
                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }
                 a {
                     text-decoration: none;
                     font-family: 'Nanum Gothic Coding', monospace;
                     display: block;
                     padding: 0.5rem;
                     transition: color 0.25s ease-in;
                     color: #888;
                     font-size: 1.2rem;
                     &:hover {
                         color: #fff;
                     }
                     @include media('<=tablet') {
                        font-size: 1rem;
                        padding: 0.75rem;
                     }
                 }
            }
        }
    }
}

