@import '../../global.scss';

.profile-page {
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
    padding: 2rem 2rem;
    max-width: 800px;
    margin: 0 auto;
    @include media('<=tablet') {
        padding: 2rem 1rem;
      }
    h1 {
        font-size: 2.5rem;
        font-weight: 400;
        @include media('<=tablet') {
            font-size: 2rem;
          }
    }
    h2 {
        
    font-size: 1.8rem;
    font-weight: 400;
    @include media('<=tablet') {
        font-size: 1rem;
      }
    }
    p {
        color: #bababa;
        font-size: 1.2rem;
        font-weight: 400;
        @include media('<=tablet') {
            font-size: 1rem;
          }
        a {
            color: #837a69;
            font-weight:bold;
        }
        strong {
            color: #837a69;
        }
    }
    .user-header {
        h1 {
            margin: 0;
            margin-bottom: 0.5rem;
        }
        h2 {
            margin-top: 0;
            color: #bababa;
        }
        margin-bottom: 2rem;
    }
    
    .prompt-box {
        h2 {
            margin-top:0;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid #45423a;
        border-radius: 2px;
    }
    
    
}