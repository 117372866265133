.figure-display {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    .figure-display-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.25s ease-in;
        width: 100%;
        
        img {
            width: 100%;
        }
        &.loaded {
            opacity: 1;
        }
    }
}