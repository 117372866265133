@import '../../../global.scss';

.element-balance {
    font-family: 'Nanum Gothic Coding', monospace;
        
        text-align: center;
        min-height: 37px;
        span {
            color: #555;
        }
    .element-timer {
        height: 4px;
        background-color: #222;
        position: relative;
        overflow: hidden;
        .element-track {
            height: 4px;
            opacity: 0.5;
        }
        .element-progress {
            height: 4px;
            width: 0%;
            background-color: #FFF;
            transition: width 6s;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.25;
        }
        .element-progress-glow {
            border-left: 5px solid #fff;
            position: absolute;
            left: -1;
            top: 0;
            height: 4px;
            opacity: 0.25;
            transform: translateX(-5px);
            transition: left 6s;
        }
    }


.element-balance-amount {
    padding: 0.5rem 0;
    color: #ccc;
    font-size: 1rem;
    @include media('<=1000px') {
        padding: 0.3rem 0;
        font-size: 0.75rem;
    }
}
    
$glowHeight: 25px;

.glow {
    &.glow-1 {
        box-shadow: 0 0 $glowHeight 5px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 $glowHeight 5px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 $glowHeight 5px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 $glowHeight 5px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 $glowHeight 5px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 $glowHeight 5px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 $glowHeight 5px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 $glowHeight 5px $colorVision;
    }
}

.glow-start, .glow-start {
    &.glow-1 {
        box-shadow: 0 0 80px 20px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 80px 20px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 80px 20px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 80px 20px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 80px 20px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 80px 20px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 80px 20px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 80px 20px $colorVision;
    }
}

.track {
}
    .track-element-1 {
        background-color: $colorDeath;
        opacity: 0.8 !important;
    }
    .track-element-2 {
        background-color: $colorLife;
        opacity: 0.4 !important;
    }
    .track-element-3 {
        background-color: $colorFire;
    }
    .track-element-4 {
        background-color: $colorWater;
    }
    .track-element-5 {
        background-color: $colorEarth;
    }
    .track-element-6 {
        background-color: $colorAir;
    }
    .track-element-7 {
        background-color: $colorMemory;
    }
    .track-element-8 {
        background-color: $colorVision;
    }
}