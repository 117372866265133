@import '../../global.scss';

$width: 156px;
$height: 769px;

$width: 1538px;
$height: 156px;

.vial-wrapper {
    padding: 0 1rem 2rem;
    
    .flask-label {
        max-width: 750px;
        font-family: 'Nanum Gothic Coding', monospace;
        font-size: 0;
        //opacity: 0.5;
        white-space: nowrap;
        margin: 0 auto;
        div {
            text-align: center;
            display: inline-block;
            transition: width 0.5s;
            width: 0%;
            line-height: 50px;
            font-size: 0.9rem;
            @include media('<=desktop-large') {
                font-size: 0.8rem;
            }
        }
    }
}

.vial {
    width : 100%;
    height: 20px;
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    .contents {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.65;
       
    }
    .contents-mask {
        z-index: 200;
        position: relative;
        width : 100%;
        height: 20px;
        overflow: hidden;
        
        border-radius: 39px;

        .balance-flask {
            height: 100%;
            font-size: 0;
            opacity: 0.5;
            white-space: nowrap;
            div {
                box-shadow: inset 0 0 7px 1px #ffffff45;
                display: inline-block;
                height: 100%;
                transition: width 0.5s;
                width: 0%;
            }
        }
    }
    .vial-bg {
        z-index: 1;
    }

    .vial-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 300;
        height: 20px;
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        .vial-left {
            background-image: url('../../image/vial-2-left.png');
        }
        .vial-right {
            background-image: url('../../image/vial-2-right.png');
        }
        .vial-center {
            background-image: url('../../image/vial-2-center.png');
        }
    }


}

.amount {
    //text-align: center;
    //font-size: 1rem;
    height: $height / 6;
    line-height: $height / 6;
    text-align: right;
    font-family: monospace;
    font-size: 18px;
    color: #ccc;
}


.vial-1 {
    background-color: $colorDeath;
}
.vial-2 {
    background-color: $colorLife;
}
.vial-3 {
    background-color: $colorFire;
}
.vial-4 {
    background-color: $colorWater;
}
.vial-5 {
    background-color: $colorEarth;
}
.vial-6 {
    background-color: $colorAir;
}
.vial-7 {
    background-color: $colorMemory;
}
.vial-8 {
    background-color: $colorVision;
}

