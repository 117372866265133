@import '../../global.scss';

$downColor : #333;
$upColor : #ccc;
.sort-arrows {
    
    display: inline-block;
    position: relative;
    vertical-align: middle;
    height: 20px;
    width: 16px;
    padding: 0 !important;
    &:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 6px 4px;
        border-color: transparent transparent $downColor transparent;
    }
    &:after {
        position: absolute;
        content: '';
        bottom: 3px;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: $downColor transparent transparent transparent;
        
    }
    &.selected {
        &:before {
            border-color: transparent transparent $upColor transparent;
        }
        &:after {
            border-color: $downColor transparent transparent transparent;
        }
    }
    &.selected.reverse {
        &:before {
            border-color: transparent transparent $downColor transparent;
        }
        &:after {
            border-color: $upColor transparent transparent transparent;
        }
    }
}

