.world-page {
    background-image: url('../../image/stone-dark.png');
    min-height: 100%;
    //overflow-y: scroll;
    .user-balance {
        position: relative;
        padding-bottom: 2px;
        &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 2px;
            background-image: url('../../image/border-bottom.png');
            display: block;
            width: 100%;
            opacity: 0.5;
        }
    }

    .world-tasks {
        list-style-type: none;
        margin: 0;
        padding: 0;
        box-shadow: 0 0 60px 0px #000;
        > li {
            position: relative;
            margin-bottom: 2px;
            &:after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                background-image: url('../../image/border-bottom.png');
                display: block;
                width: 100%;
                opacity: 0.5;
            }
        }
    }
}